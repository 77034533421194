var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-container" }, [
    _vm.savingSurveyInProgress
      ? _c(
          "div",
          { staticClass: "progress-bar-container" },
          [
            _c("label", {
              domProps: { innerHTML: _vm._s(_vm.savingProgressContent) }
            }),
            _c("br"),
            _c("progress-bar", {
              attrs: {
                options: _vm.ProgressBarOptions,
                value: _vm.progressBarValue
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.$store.state.userData.role == "super-admin" ||
    _vm.$store.state.settings.enable_survey_feature == 1
      ? _c(
          "form",
          { attrs: { "data-vv-scope": "add-survey" } },
          [
            _vm.$store.state.userData.role == "super-admin" ||
            _vm.$store.state.userData.surveyFeature == 1
              ? _c(
                  "form-wizard",
                  {
                    attrs: {
                      title: "Create Survey",
                      subtitle: "",
                      shape: "tab",
                      "back-button-text": "Back!",
                      "next-button-text": "Next!",
                      "finish-button-text": "Submit",
                      color: "#82DE6D"
                    },
                    on: {
                      "on-complete": _vm.surveySaved,
                      "on-error": _vm.handleErrorMessage
                    }
                  },
                  [
                    _c(
                      "tab-content",
                      {
                        attrs: {
                          title: "Basic Details",
                          icon: "ti-align-justify",
                          "before-change": _vm.checkBasicDetailsValidation
                        }
                      },
                      [
                        _c("BasicSurveyDetails", {
                          attrs: {
                            basicSurveyDetails: _vm.basicSurveyDetails,
                            surveyId: null
                          },
                          on: {
                            basicDetailsUpdated: _vm.updateSurveyBasicDetails
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "tab-content",
                      {
                        attrs: {
                          title: "Add Questions",
                          icon: "ti-settings",
                          "before-change": _vm.checkQuestionAnswersValidateion
                        }
                      },
                      [
                        _vm.QuestionDataExistsInLocalStorage
                          ? _c(
                              "span",
                              {
                                staticClass: "localstorage-existing-data-notice"
                              },
                              [
                                _vm._v(
                                  "You have unsaved changes in your brwoser, click "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click:
                                        _vm.fillLocalStorageQuestioncDetails
                                    }
                                  },
                                  [_vm._v("here")]
                                ),
                                _vm._v(" to prefill those!")
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.surveyQuestionAnswers, function(
                          question,
                          qi
                        ) {
                          return _c(
                            "div",
                            { key: qi, staticClass: "row single-quesion-box" },
                            [
                              _c(
                                "h3",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    color: "#782B55",
                                    "margin-bottom": "15px",
                                    "font-weight": "bold"
                                  }
                                },
                                [_vm._v("Question " + _vm._s(qi + 1))]
                              ),
                              _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: question.question,
                                      expression: "question.question"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger": _vm.errors.has(
                                      "add-survey.surveyQuestion_" + qi
                                    )
                                  },
                                  attrs: {
                                    type: "text",
                                    maxlength: "75",
                                    placeholder:
                                      "Enter Question " + (qi + 1) + " Text",
                                    name: "surveyQuestion_" + qi
                                  },
                                  domProps: { value: question.question },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        question,
                                        "question",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: question.questionType,
                                        expression: "question.questionType"
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      }
                                    ],
                                    class: {
                                      input: true,
                                      "is-danger": _vm.errors.has(
                                        "add-survey.surveyQuestionType_" + qi
                                      )
                                    },
                                    attrs: { name: "surveyQuestionType_" + qi },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          question,
                                          "questionType",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Select Answer Type")
                                    ]),
                                    _vm.basicSurveyDetails.type !== "NON_GEO"
                                      ? _c(
                                          "option",
                                          { attrs: { value: "SINGLE_ANSWER" } },
                                          [_vm._v("Single Select")]
                                        )
                                      : _vm._e(),
                                    _vm.basicSurveyDetails.type !== "NON_GEO"
                                      ? _c(
                                          "option",
                                          {
                                            attrs: { value: "MULTIPLE_ANSWER" }
                                          },
                                          [_vm._v("Multi Select")]
                                        )
                                      : _vm._e(),
                                    _c("option", { attrs: { value: "TEXT" } }, [
                                      _vm._v("Textual Value")
                                    ])
                                  ]
                                )
                              ]),
                              question.questionType !== "TEXT" &&
                              _vm.basicSurveyDetails.type !== "NON_GEO"
                                ? _c(
                                    "div",
                                    _vm._l(question.answers, function(
                                      answer,
                                      ai
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: ai,
                                          staticClass: "row answer-container"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-xs-6" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: answer.answerOption,
                                                    expression:
                                                      "answer.answerOption"
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'"
                                                  }
                                                ],
                                                staticClass: "answer-input",
                                                class: {
                                                  input: true,
                                                  "is-danger": _vm.errors.has(
                                                    "add-survey.surveyAnswers_" +
                                                      qi +
                                                      "_" +
                                                      ai
                                                  )
                                                },
                                                attrs: {
                                                  maxlength: "40",
                                                  type: "text",
                                                  placeholder:
                                                    "Answer " + (ai + 1),
                                                  name:
                                                    "surveyAnswers_" +
                                                    qi +
                                                    "_" +
                                                    ai
                                                },
                                                domProps: {
                                                  value: answer.answerOption
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      answer,
                                                      "answerOption",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              ai > 1
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "pull-right remove-answer-button",
                                                      attrs: {
                                                        href: "javascript:;"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeAnswer(
                                                            qi,
                                                            ai
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("x")]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              question.questionType != "TEXT" &&
                              _vm.basicSurveyDetails.type == "PICKL" &&
                              question.answers.length <
                                _vm.$store.state.settings
                                  .max_answers_per_survey_question
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "add-more-ans",
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addAnswerToQuestion(qi)
                                        }
                                      }
                                    },
                                    [_vm._v("Add Another Answer")]
                                  )
                                : _vm._e(),
                              question.questionType != "TEXT" &&
                              _vm.basicSurveyDetails.type == "MEDIA" &&
                              question.answers.length <
                                _vm.$store.state.settings
                                  .max_answers_per_media_survey_question
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "add-more-ans",
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addAnswerToQuestion(qi)
                                        }
                                      }
                                    },
                                    [_vm._v("Add Another Answer")]
                                  )
                                : _vm._e(),
                              qi > 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "pull-right delete-question-link",
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeQuestion(qi)
                                        }
                                      }
                                    },
                                    [_vm._v("Delete Question")]
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        _vm.basicSurveyDetails.type == "MEDIA" &&
                        _vm.surveyQuestionAnswers.length <
                          this.$store.state.settings
                            .max_questions_per_media_survey
                          ? _c(
                              "a",
                              {
                                staticClass: "add-question-button",
                                attrs: { href: "javascript:;" },
                                on: { click: _vm.AddQuestion }
                              },
                              [_vm._v("+  Question")]
                            )
                          : _vm._e(),
                        _vm.basicSurveyDetails.type == "PICKL" &&
                        _vm.surveyQuestionAnswers.length <
                          this.$store.state.settings.max_questions_per_survey
                          ? _c(
                              "a",
                              {
                                staticClass: "add-question-button",
                                attrs: { href: "javascript:;" },
                                on: { click: _vm.AddQuestion }
                              },
                              [_vm._v("+  Question")]
                            )
                          : _vm._e(),
                        _vm.basicSurveyDetails.type == "NON_GEO" &&
                        _vm.surveyQuestionAnswers.length <
                          this.$store.state.settings
                            .max_questions_per_non_geo_survey
                          ? _c(
                              "a",
                              {
                                staticClass: "add-question-button",
                                attrs: { href: "javascript:;" },
                                on: { click: _vm.AddQuestion }
                              },
                              [_vm._v("+  Question")]
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "tab-content",
                      {
                        attrs: {
                          title: "Review",
                          icon: "ti-eye",
                          "before-change": _vm.submitForm
                        }
                      },
                      [
                        _c("ReviewSurvey", {
                          attrs: {
                            basicSurveyDetails: _vm.basicSurveyDetails,
                            surveyQuestionAnswers: _vm.surveyQuestionAnswers
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "h2",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _vm._v("This feature is only available for "),
                      _c("strong", [_vm._v("Essentials")]),
                      _vm._v(" & "),
                      _c("strong", [_vm._v("Pro")]),
                      _vm._v(" plans"),
                      _c("br"),
                      _vm._v("click "),
                      _c(
                        "router-link",
                        { attrs: { to: "/brand/subscription" } },
                        [
                          _c("strong", { staticClass: "upgrade-link" }, [
                            _vm._v("Here")
                          ])
                        ]
                      ),
                      _vm._v(" to upgrade your plan")
                    ],
                    1
                  )
                ])
          ],
          1
        )
      : _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Survey Feature is not available for you!")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }